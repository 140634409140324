//TEST
// Mobile First
@mixin break($media) {
  @if (type-of($media) == "number") {
    @if (unit($media) == "px") {
      @media screen and (min-width: #{$media}) {
        @content;
      }
    }
    @else {
      @media screen and (min-width: #{$media}em) {
        @content;
      }
    }
  }
  @elseif ($media == retina) {
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      @content;
    }
  }
  @elseif ($media == ipad-only) {
    @media only screen and (min-device-width: 768px) and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      @content;
    }
  }
  @else {
    @media screen and (#{$media}) {
      @content;
    }
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  -ms-transition: $args;
  transition: $args;
}

$white: #fff;
$black: #121212;
$gray: #807f83;
$glitch-red: #ff536b;
$glitch-blue: #3ffeff;

// Media Query Variables
$small-5: 20;  // 320px *
$small-4: 25;  // 400px
$small-3: 30;  // 480px
$small-2: 40;  // 640px
$small: 43.125;  // 690px

$med-4: 46.8;  // 748px
$med-3: 48;  // 768px *
$med-2: 50;  // 800px
$med: 55;  // 880px

$large-5: 58;  // 928px
$large-4: 60;  // 960px
$large-3: 64;  // 1024px
$large-2: 68;  // 1088px
$large: 75;  // 1200px




.btn {
  @include transition(all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94));
  font-family: 'Roboto mono', sans-serif;
  font-weight: 500;
  border: 1px solid $black;
  line-height: 1.5;
  font-size: em(14px);
  padding: 16px;
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  background-color: $white;
  overflow: hidden;
  outline: none;
  text-decoration: none;
  text-align: center;
  vertical-align: bottom;

  &:focus {
    outline: none;
  }

  @include break($small-3) {
    max-width: 220px;

    &.btn-small {
      max-width: 180px;
    }
  }

  @include break($small-2) {
    display: inline-block;
  }
}

.btn-clear {
  background-color: transparent;

  &:hover {
    background-color: $black;
    color: $white;
  }

  &.btn-light {
    border-color: $white;
    color: $white;

    &:hover {
      color: $black;
      background-color: $white;
    }
  }
}

.btn-light {
  &:hover {
    background-color: $black;
    color: $white;
  }
}

.btn-dark {
  background-color: $black;
  color: $white;
  border-color: $white;

  &:hover {
    color: $gray;
  }
}

.btn-glitch {
  // Glitch stuff
  transform: translateZ(0);
  overflow: visible;

  &.btn-glitch-active {
    -webkit-filter: url('#filter');
    filter: url('#filter');

    &::after,
    &::before {
      content: '';
      width: 1px;
      position: absolute;
      top: -1px;
      bottom: -1px;
    }

    &::after {
      left: -2px;
      background-color: $glitch-red;
      animation: colorshift-red 2.6s infinite;
      animation-timing-function: step-end;
    }

    &::before {
      right: -2px;
      background-color: $glitch-blue;
      animation: colorshift-blue 2.6s infinite;
      animation-timing-function: step-end;
    }
  }
}


body {
  margin: 0;
}

.body-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;

  .dark {
    background-color: $black;
  }

  .half {
    flex: 0 0 100%;
    max-height: 50%;
    min-height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes colorshift-red {
  0%, 7% {
    background-color: $glitch-red;
  }
  8%, 18% {
    background-color: transparent;
  }
  19% {
    background-color: $glitch-red;
    width: 2px;
  }
  23%, 100% {
    background-color: transparent;
  }
}

@keyframes colorshift-blue {
  0%, 7% {
    background-color: $glitch-blue;
  }
  8%, 18% {
    background-color: transparent;
  }
  19% {
    background-color: $glitch-blue;
    width: 2px;
  }
  23%, 100% {
    background-color: transparent;
  }
}
.sidebar-list .sidebarBtn{
  background-color: #001D3A;
  font-weight: bold;
  border: none;
  height: 58px;
  text-align:left;
  padding-left: 29px;
  a {
    padding-left:8px;
  }
}
.sidebarBtn-a {
 color: white;
}
.sidebarBtn-a:hover {
  color: white;
  text-decoration:none;
}

.mt-2{
  background-color: #001D3A;
  color: #001D3A;
  margin-right: 1px;
}
.list-group{
  background-color: #001D3A;
  color: #001D3A;
  a {
    background-color: #001D3A;
    font-weight: bold;
    border: none;
    height: 58px;
    text-align:left;
    padding-left: 40px;
    display: flex;
    align-items: center;
  }
}
#collapse-2-inner {
  background-color: #001D3A;
  color: #001D3A;
}
.list-group-item-action {
  background-color: #001D3A !important;
  color: white !important;
}
.list-group-item-action::after{
  background-color: #001D3A;
  color: #001D3A;
}
.list-group-item-action::before{
  background-color: #001D3A;
  color: #001D3A;
}
.wrapper {
  position: sticky;
  height: 100%;
  width: 100%;
}
.wrapper .sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #e9ecef;
  background-size: cover;
  background-position: center center;
  overflow: unset !important;
  overflow-y: unset !important;
  height: 100%;
  .sidebar-wrapper {
    position:sticky;
    top: 150px;
    height: 90%;
    border-radius: 25px 25px 25px 25px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 244px;
    margin-top: 8px;
    margin-left: 6px;
    z-index: 4;
    box-shadow: inset -1px 0px 0px 0px #DDDDDD;
    background-color: #001D3A;
    .sidebar-list {
      transition: all .2s ease-in-out;
      z-index: 9999;
      list-style-type: none;
      padding-left:23px;
      display: block;
      display: grid;
      justify-content: space-between;
      li {
        margin-bottom: 35px;
        font-size:16px;
        a {
          color: #FFFF;
        }
      }
      li:hover {
        background-color: rgba(128, 128, 128, 0.5);
      }
    }
  }
}

.wrapper .sidebar {
  width: 260px;
  display: block;
  font-weight: 200;

  .logo {
    padding: 13px 0;
    margin: 0 20px;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      line-height: 20px;
    }

    .simple-text {
      padding: 4px 0px;
      display: block;
      font-size: 20px;
      text-align: center;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      text-decoration:none;
      .logo-img{
        width: 40px;
        display: contents;
        height: 40px;
        margin-left: 0px;
        margin-right: 10px;
        border-radius: 40px;
        text-align: center;
        vertical-align: middle;
        background-size: contain;


        img{
          max-width: 21px;
        }
      }
    }
  }

  .nav {
    margin-top: 20px;

    .nav-item {


      width: 100%;
      .nav-link {
        margin: 10px 0px;
        padding-left: 25px;
        padding-right: 25px;

        opacity: .7;
      }

      &:hover > .nav-link {
        opacity: 1;
      }

      &.active > .nav-link {
        color: #7A9E9F;
        opacity: 1;
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    i {
      font-size: 24px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}

.main-panel {
  background-color: #f4f3ef;
  position: relative;
  z-index: 2;
  float: right;
  width: calc(100% - 260px);
  min-height: 100%;

  > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px);
  }

  > .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar {
    margin-bottom: 0;
  }
}

.wrapper .sidebar,
.main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: .2s, .2s;
  transition-duration: .2s, .2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

