@import 'bootstrap-variables';
/* ==============================================================
Bootstrap tweaks
===============================================================*/
body,
h1,
h2,
h3,
h4 {
  font-weight: 300;
}

body {
  margin: 0;
  //font-family: Avenir Next,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-family: Avenir Next, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

a {
  color: #533f03;
  font-weight: bold;
}

a:hover {
  color: #533f03;
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  text-decoration: none;
  background-color: #373a3c;
}

.container-fluid {
  //position: fixed;
}

/*============================================================
register/login
============================================================*/
.rememberMe {
  float: left;
  color: white;
  margin-left: 10px;
}
.register-login-btn {
  overflow: hidden;
  border-radius: 15px;
  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;
}

.register-login-btn .slide-controlss {
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  border: 1px solid transparent;
  background: #37A0DA;
  margin: 0 20px 0 20px;
  border-radius: 30px;
  width: 268px;
  height: 53px;
}

.slide-controlss .slide {
  height: 100%;
  width: 100%;
  color: #11315A;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}

//.slide-controlss label.signup{
//color: #000;
//}
.slide-controlss .slider-tab {
  position: absolute;
  left: 0;
  z-index: 0;
  //background: -webkit-linear-gradient(left,#003366,#004080,#0059b3, #0073e6);
  background: #fff;
  transition: all 0.6s cubic-bezier(0.68, -0.25, 0.265, 1.25);
  width: 116px;
  border: 0 solid;
  border-radius: 30px;
  margin-top: 7px;
  margin-left: 8px;
  height: 38px;
  font-size: 20px;
  //color: #11315A !important;
}

input[type="radio"] {
  display: none;
}

#signup:checked ~ .slider-tab {
  left: 50%;
}

#signup:checked ~ label.signup {
  color: #11315A;
  cursor: default;
  user-select: none;
}

#signup:checked ~ label.login {
  color: #fff;
}

#signup:checked ~ label.login {
  color: #fff;
  cursor:pointer;
}

#log-inn:checked ~ label.signup {
  color: #fff;

}

#log-inn:checked ~ label.login {
  cursor: pointer;
  user-select: none;
}
.login-remember-me {
  text-align: left;
  margin-left: 6px;
  font-size: 14px;
  color: white;
}
/*============================================================
form for test
============================================================*/
//.wrapper {
//  overflow: hidden;
//  max-width: 390px;
//  background: #fff;
//  padding: 30px;
//  border-radius: 15px;
//  box-shadow: rgb(38, 57, 77) 0 20px 30px -10px;
//}
//
//.wrapper .title-text {
//  display: flex;
//  width: 200%;
//}
//
//.wrapper .title {
//  width: 50%;
//  font-size: 35px;
//  font-weight: 600;
//  text-align: center;
//  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//}
//
//.wrapper .slide-controls {
//  position: relative;
//  display: flex;
//  height: 50px;
//  width: 100%;
//  overflow: hidden;
//  margin: 30px 0 10px 0;
//  justify-content: space-between;
//  border: 1px solid lightgrey;
//  border-radius: 15px;
//}
//
//.slide-controls .slide {
//  height: 100%;
//  width: 100%;
//  color: #fff;
//  font-size: 18px;
//  font-weight: 500;
//  text-align: center;
//  line-height: 48px;
//  cursor: pointer;
//  z-index: 1;
//  transition: all 0.6s ease;
//}
//
//.slide-controls label.signup {
//  color: #000;
//}
//
//.slide-controls .slider-tab {
//  position: absolute;
//  height: 100%;
//  width: 50%;
//  left: 0;
//  z-index: 0;
//  border-radius: 15px;
//  background: linear-gradient(to left, #003366, #004080, #0059b3, #0073e6);
//  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//}
//
//input[type="radio"] {
//  display: none;
//}
//
//#signup:checked ~ .slider-tab {
//  left: 50%;
//}
//
//#signup:checked ~ label.signup {
//  color: #fff;
//  //color: green;
//  cursor: default;
//  user-select: none;
//  //background:red;
//}
//
//#signup:checked ~ label.login {
//  color: #000;
//}
//
//#log-in:checked ~ label.signup {
//  color: #000;
//  //color:red;
//}
//
//#log-in:checked ~ label.login {
//  cursor: default;
//  user-select: none;
//}
//
//.wrapper .form-container {
//  width: 100%;
//  overflow: hidden;
//}
//
//.form-container .form-inner {
//  display: flex;
//  width: 200%;
//}
//
//.form-container .form-inner form {
//  width: 50%;
//  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//}
//
//.form-inner form .field {
//  height: 50px;
//  width: 100%;
//  margin-top: 20px;
//}
//
//.form-inner form .field input {
//  height: 100%;
//  width: 100%;
//  outline: none;
//  padding-left: 15px;
//  border-radius: 15px;
//  border: 1px solid lightgrey;
//  border-bottom-width: 2px;
//  font-size: 17px;
//  transition: all 0.3s ease;
//}
//
//.form-inner form .field input:focus {
//  border-color: #1a75ff;
//  /* box-shadow: inset 0 0 3px #fb6aae; */
//}
//
//.form-inner form .field input::placeholder {
//  color: #999;
//  transition: all 0.3s ease;
//}
//
//form .field input:focus::placeholder {
//  color: #1a75ff;
//}
//
//.form-inner form .pass-link {
//  margin-top: 5px;
//}
//
//.form-inner form .signup-link {
//  text-align: center;
//  margin-top: 30px;
//}
//
//.form-inner form .pass-link a,
//.form-inner form .signup-link a {
//  color: #1a75ff;
//  text-decoration: none;
//}
//
//.form-inner form .pass-link a:hover,
//.form-inner form .signup-link a:hover {
//  text-decoration: underline;
//}
//
//form .btn {
//  height: 50px;
//  width: 100%;
//  border-radius: 15px;
//  position: relative;
//  overflow: hidden;
//}
//
//form .btn .btn-layer {
//  height: 100%;
//  width: 300%;
//  position: absolute;
//  left: -100%;
//  background: -webkit-linear-gradient(right, #003366, #004080, #0059b3, #0073e6);
//  border-radius: 15px;
//  transition: all 0.4s ease;;
//}
//
//form .btn:hover .btn-layer {
//  left: 0;
//}
//
//form .btn input[type="submit"] {
//  height: 100%;
//  width: 100%;
//  z-index: 1;
//  position: relative;
//  background: none;
//  border: none;
//  color: #fff;
//  padding-left: 0;
//  border-radius: 15px;
//  font-size: 20px;
//  font-weight: 500;
//  cursor: pointer;
//}

/* ==========================================================================
Register Page
========================================================================== */
#register-page {
  background-image: url("../images/LYDSEC-Keypasco-SaaS-Background.jpg");
  background-size: contain;
  padding-bottom: 65px;
  margin-left: -1px;
  text-align: center;
}

#register-page .body-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  }

.body-row {
  img {
    width: 75%;
    border-radius: 20px;
  }

  .upper-container .title {
    text-align: center;
    font-size: 24px;
    color: white;
    margin: 25px 0 3px;
    padding-right: 135px;
  }

  .upper-container .subtitle {
    text-align: center;
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
    padding-right: 135px;
  }

  .upper-container .requested {
    text-align: right;
    font-size: 14px;
    color: white;
    padding-right: 120px;
    margin-bottom: 8px;
    font-weight: 900;
  }

  .upper-container {
    text-align: center;
    padding-left: 0;
    padding-right: 51px;
    margin-left: 130px;
  }
}

#register-form {
  box-sizing: border-box;
  width: 710px;
  height: 565px;
  padding-left: 0;
  padding-right: 51px;
  display: block;

  form input {
    height: 60px;
    width: 300px;
    display: block;
    margin: auto auto 30px;
    font-size: 24px;
    text-indent: 26px;
    border-radius: 20px;
    border-style: none;
  }

  form select {
    width: 209px;
    height: 60px;
    margin-left: 8px;
    display: block;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    border-style: none;
  }

  form .upper-form-row .col-md-6 {
    padding-right: 20px;
    padding-left: 20px;
  }

  form .telephone-row {
    display: flex;
    margin: auto;
  }
}

#country-code {
  border-radius: 20px 0 0 20px;
  text-indent: 26PX;
}

#register-button button {
  height: 60px;
  display: block;
  width: 300px;
  background: #37A0DA;
  margin: 30px auto auto;
  font-size: 28px;
  float: inside;
  text-align: center;
  border-radius: 20px;
  border-style: unset;
}

@media (max-width: 1900px){
  .item8{
  padding-right: 193px;
  }
}
/* ==========================================================================
Login Page
========================================================================== */
#login-button button {
  height: 60px;
  display: block;
  width: 300px;
  background: #37A0DA;
  margin: 30px auto auto;
  font-size: 28px;
  float: inside;
  text-align: center;
  border-radius: 20px;
  border-style: unset;
}
/* ==========================================================================
Breadcrumb Style
========================================================================== */
.main-panel {
  background-color: #e9ecef;
  .container-card {
    padding:0 68px 0 68px;
    .card-header {
      background-color: #e9ecef;
    }
    .breadcrumb                                                                                                                                                                                                       {
      background-color: #C1CADA;
      //margin: 39px 56px 36px 56px;
      margin: 39px 0px 36px 0px;
      border-radius: 10px;
      box-shadow: 0 4px 1px -1px rgba(0, 0, 0, .1), 0 2px 1px -1px rgba(0, 0, 0, .06);
    }
  }
}

  /* ==========================================================================
  Browser Upgrade Prompt
  ========================================================================== */
  .browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }
  /* ==========================================================================
  Main page styles
  ========================================================================== */

  .hipster.img-fluid {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url('../images/jhipster_family_member_3.svg') no-repeat center top;
    background-size: contain;
  }

  /* wait autoprefixer update to allow simple generation of high pixel density media query */
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    .hipster {
      background: url('../images/jhipster_family_member_3.svg') no-repeat center top;
      background-size: contain;
    }
  }
  /* ==========================================================================
  Generic styles
  ========================================================================== */
  /* Error highlight on input fields */
  .valid[required],
  .valid.required {
    border-left: 5px solid green;
  }

  .invalid:not(form) {
    border-left: 5px solid red;
  }

  /* other generic styles */

  .jh-card {
    height: 783px;
  }

  .error {
    color: white;
    background-color: red;
  }

  .pad {
    padding: 10px;
  }

  .w-40 {
    width: 40% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .break {
    white-space: normal;
    word-break: break-all;
  }

  .readonly {
    background-color: #eee;
    opacity: 1;
  }

  .footer {
    position: fixed !important;
    border-style: none;
    height: 65px; /*設定footer本身高度*/
    background-image: url("../images/LYDSEC-Keypasco SaaS-Footer.jpg");
    background-size: contain;
    width: max-content;
    width: 100%;
    margin-top: auto;
    bottom: 0;
    justify-content: center; /* 将内容水平居中 */
    align-items: center; /* 将内容垂直居中 */
    padding-right: 260px;
    //margin-left: -20px;

    img {
      width: 64px;
      margin-left: 60px;
      margin-top: 20px;
      float: left;
      display: flex;
    }
  }

  .footer-links {
    width: 1800px;
    margin-top: 16px;
  }

  .grid-container {
    color: white;
    width: 25%;
    float: left;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    padding-left: 22px;
  }

  .footer-links-2 {
    color: white;
    width: 75%;
    float: right;
    padding-right: 80px;
    text-align: right;
  }

  .hand,
  [jhisortby] {
    cursor: pointer;
  }

  /* ==========================================================================
  Custom alerts for notification
  ========================================================================== */
  .alerts {
    .alert {
      text-overflow: ellipsis;

      pre {
        background: none;
        border: none;
        font: inherit;
        color: inherit;
        padding: 0;
        margin: 0;
      }

      .popover pre {
        font-size: 10px;
      }
    }

    .toast {
      position: fixed;
      width: 100%;

      &.left {
        left: 5px;
      }

      &.right {
        right: 5px;
      }

      &.top {
        top: 55px;
      }

      &.bottom {
        bottom: 55px;
      }
    }
  }

  @media screen and (min-width: 480px) {
    .alerts .toast {
      width: 50%;
    }
  }

  /* ==========================================================================
  entity tables helpers
  ========================================================================== */

  /* Remove Bootstrap padding from the element
  http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
  @mixin no-padding($side) {
    @if $side == 'all' {
      .no-padding {
        padding: 0 !important;
      }
    } @else {
      .no-padding-#{$side} {
        padding-#{$side}: 0 !important;
      }
    }
  }

  @include no-padding('left');
  @include no-padding('right');
  @include no-padding('top');
  @include no-padding('bottom');
  @include no-padding('all');

  /* bootstrap 3 input-group 100% width
  http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
  .width-min {
    width: 1% !important;
  }

  /* ==========================================================================
  entity detail page css
  ========================================================================== */
  .row.jh-entity-details {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    line-height: 1.5;
  }

  @media screen and (min-width: 768px) {
    .row.jh-entity-details > {
      dt {
        float: left;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0.5em 0;
      }

      dd {
        border-bottom: 1px solid #eee;
        padding: 0.5em 0;
        margin-left: 0;
      }
    }
  }

  /* ==========================================================================
  ui bootstrap tweaks
  ========================================================================== */
  .nav,
  .pagination,
  .carousel,
  .card-title a {
    cursor: pointer;
  }

  .datetime-picker-dropdown > li.date-picker-menu div > table .btn-secondary,
  .uib-datepicker-popup > li > div.uib-datepicker > table .btn-secondary {
    border: 0;
  }

  .datetime-picker-dropdown > li.date-picker-menu div > table:focus,
  .uib-datepicker-popup > li > div.uib-datepicker > table:focus {
    outline: none;
  }

  .thread-dump-modal-lock {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

/* jhipster-needle-scss-add-main JHipster will add new css style */
.ml-auto {
  padding-top: 31px;
}
