<template>
  <div>
    <b-navbar data-cy="navbar" toggleable="md" type="dark" class="bg-primary">
      <b-navbar-brand class="logo" b-link to="/">
        <span class="logo-img"></span>
        <span v-text="$t('global.title')" class="navbar-title">keypasco Saas</span>
<!--        <span class="login-register">Login/Register</span>-->
        <!--      <span v-text="$t('global.login-register')" class="login-register">Login/Register</span>-->
        <!--      <span class="navbar-version">{{ version }}</span>-->
      </b-navbar-brand>
      <b-navbar-toggle
        right
        class="jh-navbar-toggler d-lg-none"
        href="javascript:void(0);"
        data-toggle="collapse"
        target="header-tabs"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <font-awesome-icon icon="bars"/>
      </b-navbar-toggle>

      <b-collapse is-nav id="header-tabs">
        <b-navbar-nav class="ml-auto">
          <!--        <b-nav-item to="/" exact>-->
          <!--          <span>-->
          <!--            <font-awesome-icon icon="home" />-->
          <!--            <span v-text="$t('global.menu.home')">Home</span>-->
          <!--          </span>-->
          <!--        </b-nav-item>-->
          <b-nav-item-dropdown right id="entity-menu" v-if="authenticated" active-class="active" class="pointer"
                               data-cy="entity">
            <span slot="button-content" class="navbar-dropdown-menu">
              <font-awesome-icon icon="th-list"/>
              <span class="no-bold" v-text="$t('global.menu.entities.main')">Entities</span>
            </span>
            <entities-menu></entities-menu>
            <!-- jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here -->
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            right
            id="admin-menu"
            v-if="hasAnyAuthority('ROLE_ADMIN') && authenticated"
            :class="{ 'router-link-active': subIsActive('/admin') }"
            active-class="active"
            class="pointer"
            data-cy="adminMenu"
          >
            <span slot="button-content" class="navbar-dropdown-menu">
              <font-awesome-icon icon="users-cog"/>
              <span class="no-bold" v-text="$t('global.menu.admin.main')">Administration</span>
            </span>
            <b-dropdown-item to="/admin/user-management" active-class="active">
              <font-awesome-icon icon="users"/>
              <span v-text="$t('global.menu.admin.userManagement')">User management</span>
            </b-dropdown-item>
            <b-dropdown-item to="/admin/metrics" active-class="active">
              <font-awesome-icon icon="tachometer-alt"/>
              <span v-text="$t('global.menu.admin.metrics')">Metrics</span>
            </b-dropdown-item>
            <b-dropdown-item to="/admin/health" active-class="active">
              <font-awesome-icon icon="heart"/>
              <span v-text="$t('global.menu.admin.health')">Health</span>
            </b-dropdown-item>
            <b-dropdown-item to="/admin/configuration" active-class="active">
              <font-awesome-icon icon="cogs"/>
              <span v-text="$t('global.menu.admin.configuration')">Configuration</span>
            </b-dropdown-item>
            <b-dropdown-item to="/admin/logs" active-class="active">
              <font-awesome-icon icon="tasks"/>
              <span v-text="$t('global.menu.admin.logs')">Logs</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="openAPIEnabled" to="/admin/docs" active-class="active">
              <font-awesome-icon icon="book"/>
              <span v-text="$t('global.menu.admin.apidocs')">API</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="!inProduction" href="http://localhost:8092/" target="_tab">
              <font-awesome-icon icon="database"/>
              <span v-text="$t('global.menu.admin.database')">Database</span>
            </b-dropdown-item>
            <b-dropdown-item to="/admin/device-control" active-class="active">
              <font-awesome-icon icon="users" />
              <span v-text="$t('global.menu.admin.devicecontrol')">Device Control</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            right
            href="javascript:void(0);"
            id="account-menu"
            :class="{ 'router-link-active': subIsActive('/account') }"
            active-class="active"
            class="pointer"
            data-cy="accountMenu"
          >
            <span slot="button-content" class="navbar-dropdown-menu">
              <font-awesome-icon icon="user"/>
              <span class="no-bold" v-text="$t('global.menu.account.main')"> Account </span>
            </span>
            <b-dropdown-item data-cy="settings" to="/account/settings" tag="b-dropdown-item" v-if="authenticated"
                             active-class="active">
              <font-awesome-icon icon="wrench"/>
              <span v-text="$t('global.menu.account.settings')">Settings</span>
            </b-dropdown-item>
            <b-dropdown-item data-cy="passwordItem" to="/account/password" tag="b-dropdown-item" v-if="authenticated"
                             active-class="active">
              <font-awesome-icon icon="lock"/>
              <span v-text="$t('global.menu.account.password')">Password</span>
            </b-dropdown-item>
            <b-dropdown-item data-cy="logout" v-if="authenticated" v-on:click="logout()" id="logout"
                             active-class="active">
              <font-awesome-icon icon="sign-out-alt"/>
              <span v-text="$t('global.menu.account.logout')">Sign out</span>
            </b-dropdown-item>
            <b-dropdown-item data-cy="login" v-if="!authenticated" v-on:click="openLogin()" id="login"
                             active-class="active">
              <font-awesome-icon icon="sign-in-alt"/>
              <span v-text="$t('global.menu.account.login')">Sign in</span>
            </b-dropdown-item>
            <b-dropdown-item
              data-cy="register"
              to="/register"
              tag="b-dropdown-item"
              id="register"
              v-if="!authenticated"
              active-class="active"
            >
              <font-awesome-icon icon="user-plus"/>
              <span v-text="$t('global.menu.account.register')">Register</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!--          <div class="register-right">-->
          <!--            <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">-->
          <!--              <li class="nav-item">-->
          <!--                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Login</a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a class="nav-link" id="profile-tab"  data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Sign Up</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->
          <div class="register-login-btn">
            <div class="slide-controlss">
              <input @click="loginSwitchBtnClick" type="radio" name="slide" id="log-inn" checked>
              <input @click="handleLabelClick" type="radio" name="slide" id="signup">
              <label for="log-inn" class="slide login">Login</label>
              <label for="signup" class="slide signup">Signup</label>
              <div class="slider-tab"></div>
            </div>
          </div>

          <!--          <div class="wrapper">-->
          <!--            <div class="title-text">-->
          <!--              <div class="title login">Login Form</div>-->
          <!--              <div class="title signup">Signup Form</div>-->
          <!--            </div>-->
          <!--            <div class="form-container">-->
          <!--              <div class="slide-controls">-->
          <!--                <input type="radio" name="slide" id="log-in" checked>-->
          <!--                <input type="radio" name="slide" id="signup">-->
          <!--                <label for="log-in" class="slide login" v-on:click="slideLogin()">Login</label>-->
          <!--                <label for="signup" class="slide signup" v-on:click="slideSignup()">Signup</label>-->
          <!--                <div class="slider-tab"></div>-->
          <!--              </div>-->
          <!--              <div class="form-inner">-->
          <!--                <form action="#" class="login">-->
          <!--            <pre>-->
          <!--            </pre>-->
          <!--                  <div class="field">-->
          <!--                    <input type="text" placeholder="Email Address" required>-->
          <!--                  </div>-->
          <!--                  <div class="field">-->
          <!--                    <input type="password" placeholder="Password" required>-->
          <!--                  </div>-->
          <!--                  <div class="pass-link"><a href="#">Forgot password?</a></div>-->
          <!--                  <div class="field btn">-->
          <!--                    <div class="btn-layer"></div>-->
          <!--                    <input type="submit" value="Login">-->
          <!--                  </div>-->
          <!--                  <div class="signup-link">Create an account <a href="">Signup now</a></div>-->
          <!--                </form>-->
          <!--                <form action="#" class="signup">-->
          <!--                  <div class="field">-->
          <!--                    <input type="text" placeholder="Name" required>-->
          <!--                  </div>-->
          <!--                  <div class="field">-->
          <!--                    <input type="text" placeholder="Email Address" required>-->
          <!--                  </div>-->
          <!--                  <div class="field">-->
          <!--                    <input type="password" placeholder="Password" required>-->
          <!--                  </div>-->
          <!--                  <div class="field">-->
          <!--                    <input type="password" placeholder="Confirm password" required>-->
          <!--                  </div>-->
          <!--                  <div class="field btn">-->
          <!--                    <div class="btn-layer"></div>-->
          <!--                    <input type="submit" value="Signup">-->
          <!--                  </div>-->
          <!--                  <div class="signup-link">Already have an account? <a href="">Login</a></div>-->
          <!--                </form>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->


          <b-nav-item-dropdown id="languagesnavBarDropdown" right v-if="languages && Object.keys(languages).length > 1">
            <span slot="button-content">
  <!--            <font-awesome-icon icon="flag" />-->
              <img src='/../content/images/LYDSEC-Keypasco SaaS-Language.png' alt="footer logo picture"/>
              <!--            <span class="no-bold" v-text="$t('global.menu.language')">Language</span>-->
            </span>
            <b-dropdown-item
              v-for="(value, key) in languages"
              :key="`lang-${key}`"
              v-on:click="changeLanguage(key)"
              :class="{ active: isActiveLanguage(key) }"
            >
              {{ value.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>


        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script lang="ts" src="./jhi-navbar.component.ts"></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* ==========================================================================
    Navbar
    ========================================================================== */
.register-right {
  background: #37A0DA;
  /*border-top-left-radius: 10% 50%;*/
  margin:0 20px 0 20px;
  border-radius: 30px;
  width: 268px;
  height: 53px;
}
.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}
.register-right .nav-tabs {
  border-bottom: 1px solid #ecf0f1;
  border-style: none;
  border-radius: 69px;
  width: 95%;
  float: right;
}
.register-right .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  font-size: 20px;
  margin-top: 6%;
}
.register-right .nav-tabs .nav-link:hover {
  border: none;
}
.register-right .nav-tabs .nav-link.active {
  /*background: red;*/
  width: 116px;
  border: 0px solid #37A0DA;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  margin-top: 6%;
  height: 38px;
  font-size: 20px;
  color: #37A0DA !important;
}
/*sssssssssssssssssssssssssssssssssss*/
.navbar-version {
  font-size: 10px;
}

@media screen and (min-width: 768px) {
  .jh-navbar-toggler {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  span span {
    display: none;
  }
}

.navbar-title {
  display: inline-block;
  vertical-align: middle;
}

/* ==========================================================================
    Logo styles
    ========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px;
}

.logo .logo-img {
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}

.login-register{
  margin-top: 11px;
}

.logo-img {
  height: 100%;
  background: url('../../../content/images/LYDSEC-Keypasco- SaaS-Key-Header.png') no-repeat center center;
  background-size: contain;
  width: 100%;
  filter: drop-shadow(0 0 0.05rem white);
}

.bg-primary {
  background-image: url("../../../content/images/LYDSEC-Keypasco-SaaS-Background.jpg");
  background-size: contain;
  width: max-content;
  margin-left: -20px;
  padding-right: 112px;
  width:auto;
}
/* ==========================================================================
    Navbar test
    ========================================================================== */
/*@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');*/
/**{*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  box-sizing: border-box;*/
/*  font-family: 'Poppins', sans-serif;*/
/*}*/
/*html,body{*/
/*  display: grid;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  place-items: center;*/
/*  !*background-color: #8EC5FC;*!*/
/*  background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);*/

/*}*/
/*::selection{*/
/*  background: #1a75ff;*/
/*  color: #fff;*/
/*}*/
/*.wrapper{*/
/*  overflow: hidden;*/
/*  max-width: 390px;*/
/*  background: #fff;*/
/*  padding: 30px;*/
/*  border-radius: 15px;*/
/*  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;*/
/*}*/
/*.wrapper .title-text{*/
/*  display: flex;*/
/*  width: 200%;*/
/*}*/
/*.wrapper .title{*/
/*  width: 50%;*/
/*  font-size: 35px;*/
/*  font-weight: 600;*/
/*  text-align: center;*/
/*  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);*/
/*}*/
/*.wrapper .slide-controls{*/
/*  position: relative;*/
/*  display: flex;*/
/*  height: 50px;*/
/*  width: 100%;*/
/*  overflow: hidden;*/
/*  margin: 30px 0 10px 0;*/
/*  justify-content: space-between;*/
/*  border: 1px solid lightgrey;*/
/*  border-radius: 15px;*/
/*}*/
/*.slide-controls .slide{*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  color: #fff;*/
/*  font-size: 18px;*/
/*  font-weight: 500;*/
/*  text-align: center;*/
/*  line-height: 48px;*/
/*  cursor: pointer;*/
/*  z-index: 1;*/
/*  transition: all 0.6s ease;*/
/*}*/
/*.slide-controls label.signup{*/
/*  color: #000;*/
/*}*/
/*.slide-controls .slider-tab{*/
/*  position: absolute;*/
/*  height: 100%;*/
/*  width: 50%;*/
/*  left: 0;*/
/*  z-index: 0;*/
/*  border-radius: 15px;*/
/*  background: -webkit-linear-gradient(left,#003366,#004080,#0059b3*/
/*  , #0073e6);*/
/*  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);*/
/*}*/
/*input[type="radio"]{*/
/*  display: none;*/
/*}*/
/*#signup:checked ~ .slider-tab {*/
/*  left: 50%;*/
/*}*/
/*#signup:checked ~ label.signup{*/
/*  !*color: #fff;*!*/
/*  color: green;*/
/*  cursor: default;*/
/*  user-select: none;*/
/*  background:red;*/
/*}*/
/*#signup:checked ~ label.login{*/
/*  color: #000;*/
/*}*/
/*#login:checked ~ label.signup{*/
/*  !*color: #000;*!*/
/*  color:red;*/
/*}*/
/*#login:checked ~ label.login{*/
/*  cursor: default;*/
/*  user-select: none;*/
/*}*/

/*.wrapper .form-container{*/
/*  width: 100%;*/
/*  overflow: hidden;*/
/*}*/
/*.form-container .form-inner{*/
/*  display: flex;*/
/*  width: 200%;*/
/*}*/
/*.form-container .form-inner form{*/
/*  width: 50%;*/
/*  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);*/
/*}*/
/*.form-inner form .field{*/
/*  height: 50px;*/
/*  width: 100%;*/
/*  margin-top: 20px;*/
/*}*/
/*.form-inner form .field input{*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  outline: none;*/
/*  padding-left: 15px;*/
/*  border-radius: 15px;*/
/*  border: 1px solid lightgrey;*/
/*  border-bottom-width: 2px;*/
/*  font-size: 17px;*/
/*  transition: all 0.3s ease;*/
/*}*/
/*.form-inner form .field input:focus{*/
/*  border-color: #1a75ff;*/
/*  !* box-shadow: inset 0 0 3px #fb6aae; *!*/
/*}*/
/*.form-inner form .field input::placeholder{*/
/*  color: #999;*/
/*  transition: all 0.3s ease;*/
/*}*/
/*form .field input:focus::placeholder{*/
/*  color: #1a75ff;*/
/*}*/
/*.form-inner form .pass-link{*/
/*  margin-top: 5px;*/
/*}*/
/*.form-inner form .signup-link{*/
/*  text-align: center;*/
/*  margin-top: 30px;*/
/*}*/
/*.form-inner form .pass-link a,*/
/*.form-inner form .signup-link a{*/
/*  color: #1a75ff;*/
/*  text-decoration: none;*/
/*}*/
/*.form-inner form .pass-link a:hover,*/
/*.form-inner form .signup-link a:hover{*/
/*  text-decoration: underline;*/
/*}*/
/*form .btn{*/
/*  height: 50px;*/
/*  width: 100%;*/
/*  border-radius: 15px;*/
/*  position: relative;*/
/*  overflow: hidden;*/
/*}*/
/*form .btn .btn-layer{*/
/*  height: 100%;*/
/*  width: 300%;*/
/*  position: absolute;*/
/*  left: -100%;*/
/*  background: -webkit-linear-gradient(right,#003366,#004080,#0059b3*/
/*  , #0073e6);*/
/*  border-radius: 15px;*/
/*  transition: all 0.4s ease;;*/
/*}*/
/*form .btn:hover .btn-layer{*/
/*  left: 0;*/
/*}*/
/*form .btn input[type="submit"]{*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  z-index: 1;*/
/*  position: relative;*/
/*  background: none;*/
/*  border: none;*/
/*  color: #fff;*/
/*  padding-left: 0;*/
/*  border-radius: 15px;*/
/*  font-size: 20px;*/
/*  font-weight: 500;*/
/*  cursor: pointer;*/
/*}*/
</style>
