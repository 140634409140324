#test {
  background-image: url("../images/LYDSEC-Keypasco-SaaS-Background.jpg");
  background-size: contain;
  padding-bottom: 103px;
  justify-content: center;
  align-items: center;
  margin-left: -1px;
}

#test .pricing-row {
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
  margin-right: 0;
  margin-left: 0;

  .col-md-3 {
    display: flex;
    padding-left: 25px;

    .frame-form {
      padding-left: 10px;
      padding-right: 10px;
      background-color: rgba(255, 255, 255, 0.25);
      height: 928px;
      width: 360px;
      border-radius: 30px;
    }
  }
}

.box-1 .frame-form {
  .price-row {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    font-size: 45px;
    color: #66E2FF;
    margin-top: 18px;
    margin-bottom: -20px;
  }

  .left-box {
    color: #64E2FF;
  }

  .right-box {
    text-align: right;
    color: #64E2FF;
  }

  .country-dollar-left {
    font-size: 18px;
    font-weight: 900;
    margin-left: -23px;
    color: #64E2FF;
  }

  .country-dollar-right {
    font-size: 18px;
    font-weight: 900;
    color: #64E2FF;
  }

  .price-number-left {
    font-size: 50px;
    font-weight: 900;
    color: #64E2FF;
  }

  .price-number-right {
    font-size: 50px;
    font-weight: 900;
    color: #64E2FF;
  }

  .user-mon {
    font-size: 12px;
    color: #64E2FF;
  }

  .slash {
    font-size: 100px;
    color: #64E2FF;
  }

  .pricing-button button {
    height: 48px;
    display: block;
    width: 280px;
    background: #66E2FF;
    border-radius: 24px;
    margin: 26px auto 33px;
    border-style: none;
    font-size: 24px;
  }

  .benefit-list ul li::before {
    /*   可以自訂其他形狀 */
    content: "\2022";
    /*   顏色 */
    color: #64E2FF;
    /*   設定 display */
    display: inline-block;
    /*  寬度  */
    width: 1rem;
    /*  可以視情況調整形狀的距離  */
    margin-left: -10px;
    margin-right: 10px;
    font-size: 28px;
  }
}

.box-2 .frame-form {
  .price-row {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    font-size: 45px;
    color: #66E2FF;
    margin-top: 18px;
    margin-bottom: -20px;
  }

  .left-box {
    color: #71A0FF;
  }

  .right-box {
    text-align: right;
    color: #71A0FF;
  }

  .country-dollar-left {
    font-size: 18px;
    font-weight: 900;
    margin-left: -23px;
    color: #71A0FF;
  }

  .country-dollar-right {
    font-size: 18px;
    font-weight: 900;
    color: #71A0FF;
  }

  .price-number-left {
    font-size: 50px;
    font-weight: 900;
    color: #71A0FF;
  }

  .price-number-right {
    font-size: 50px;
    font-weight: 900;
    color: #71A0FF;
  }

  .user-mon {
    font-size: 12px;
    color: #71A0FF;
  }

  .slash {
    font-size: 100px;
    color: #71A0FF;
  }

  .pricing-button button {
    height: 48px;
    display: block;
    width: 280px;
    background: #71A0FF;
    border-radius: 24px;
    margin: 26px auto 33px;
    border-style: none;
    font-size: 24px;
  }

  .benefit-list ul li::before {
    /*   可以自訂其他形狀 */
    content: "\2022";
    /*   顏色 */
    color: #71A0FF;
    /*   設定 display */
    display: inline-block;
    /*  寬度  */
    width: 1rem;
    /*  可以視情況調整形狀的距離  */
    margin-left: -10px;
    margin-right: 10px;
    font-size: 28px;
  }
}

.box-3 .frame-form {
  .price-row {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    font-size: 45px;
    color: #66E2FF;
    margin-top: 18px;
    margin-bottom: -20px;
  }

  .left-box {
    color: #77C1E6;
  }

  .right-box {
    text-align: right;
    color: #77C1E6;
  }

  .country-dollar-left {
    font-size: 18px;
    font-weight: 900;
    margin-left: -23px;
    color: #77C1E6;
  }

  .country-dollar-right {
    font-size: 18px;
    font-weight: 900;
    color: #77C1E6;
  }

  .price-number-left {
    font-size: 50px;
    font-weight: 900;
    color: #77C1E6;
  }

  .price-number-right {
    font-size: 50px;
    font-weight: 900;
    color: #77C1E6;
  }

  .user-mon {
    font-size: 12px;
    color: #77C1E6;
  }

  .slash {
    font-size: 100px;
    color: #77C1E6;
  }

  .pricing-button button {
    height: 48px;
    display: block;
    width: 280px;
    background: #77C1E6;
    border-radius: 24px;
    margin: 26px auto 33px;
    border-style: none;
    font-size: 24px;
  }

  .benefit-list ul li::before {
    /*   可以自訂其他形狀 */
    content: "\2022";
    /*   顏色 */
    color: #77C1E6;
    /*   設定 display */
    display: inline-block;
    /*  寬度  */
    width: 1rem;
    /*  可以視情況調整形狀的距離  */
    margin-left: -10px;
    margin-right: 10px;
    font-size: 28px;
  }
}


.box-4 .frame-form {
  .price-row {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    font-size: 45px;
    color: #66E2FF;
    margin-top: 18px;
    margin-bottom: -20px;
  }

  .left-box {
    color: #37A0DA;
  }

  .right-box {
    text-align: right;
    color: #37A0DA;
  }

  .country-dollar-left {
    font-size: 18px;
    font-weight: 900;
    margin-left: -23px;
    color: #37A0DA;
  }

  .country-dollar-right {
    font-size: 18px;
    font-weight: 900;
    color: #37A0DA;
  }

  .price-number-left {
    font-size: 50px;
    font-weight: 900;
    color: #37A0DA;
  }

  .price-number-right {
    font-size: 50px;
    font-weight: 900;
    color: #37A0DA;
  }

  .user-mon {
    font-size: 12px;
    color: #37A0DA;
  }

  .slash {
    font-size: 100px;
    color: #37A0DA;
  }

  .pricing-button button {
    height: 48px;
    display: block;
    width: 280px;
    background: #37A0DA;
    border-radius: 24px;
    margin: 26px auto 33px;
    border-style: none;
    font-size: 24px;
  }

  .benefit-list ul li::before {
    /*   可以自訂其他形狀 */
    content: "\2022";
    /*   顏色 */
    color: #37A0DA;
    /*   設定 display */
    display: inline-block;
    /*  寬度  */
    width: 1rem;
    /*  可以視情況調整形狀的距離  */
    margin-left: -10px;
    margin-right: 10px;
    font-size: 28px;
  }
}

.pricing-button button {
  height: 48px;
  display: block;
  width: 280px;
  background: #37A0DA;
  border-radius: 24px;
  margin: 26px auto 33px;
  border-style: none;
  font-size: 24px;
}

.benefit-list ul li::before {
  /*   可以自訂其他形狀 */
  content: "\2022";
  /*   顏色 */
  color: #37A0DA;
  /*   設定 display */
  display: inline-block;
  /*  寬度  */
  width: 1rem;
  /*  可以視情況調整形狀的距離  */
  margin-left: -10px;
  margin-right: 10px;
  font-size: 28px;
}

.entry-level-title {
  text-align: center;
  font-size: 48px;
  padding-top: 40px;
  color: #FFFFFF;
  font-weight: 600;
}

.mode {
  text-align: center;
  font-size: 26px;
  color: #001D3A;
  margin-top: -11px;
  font-weight: 600;
}

.benefit-list {
  text-align: left;

  ul {
    list-style: none;
    color: #FFFFFF;
  }
}

@media (max-width: 1534px) {
  .pricing-row {
    padding-left:100px;
    padding-right:100px;
    text-align: center;
    .frame-form {
      transform: scale(0.9);
    }
  }
}

@media (max-width: 1340px) {
  .pricing-row {
    padding-left:100px;
    padding-right:100px;
    text-align: center;
    .frame-form {
      transform: scale(0.9);
      height: 1076px !important;
    }
  }
}

@media (max-width: 1072px) {
  .pricing-row {
    //transform: scale(0.9);
    padding-right: 50px !important;
    padding-left: 0px !important;
    //text-align: center;
    //width: 50%;
    //margin: 0 auto;
  }
}
